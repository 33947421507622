import React from "react"
import { Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"

const PaymentBenefitsPopup = props => {
  if (props.popData.length === 0) {
    return (
      <div
        className="modal fade show"
        style={{ display: props.visiblity }}
      ></div>
    )
  } else {
    return (
      <Modal
        show={props.visiblity}
        animation={true}
        centered={true}
        size="lg"
        id="paymentoption"
      >
        <div className="d-sm-flex">
          <div className="bg-white p-5 d-flex align-items-center justify-content-center">
            {(() =>
              props?.popData?.paymentOptionIcon?.caption ? (
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={RemoveHTML(props?.popData?.paymentOptionIcon?.caption)}
                  preserveAspectRatio="none"
                />
              ) : (
                <img
                  className="img-fluid"
                  src={props.popData.paymentOptionIcon.sourceUrl}
                  alt={props.popData.paymentOptionIcon.altText}
                />
              ))()}
            {/* <img src={props.popData.paymentOptionIcon.sourceUrl} id="modalIcon" width="150" alt={props.popData.fieldGroupName} /> */}
          </div>
          <div className="benefit-content p-4 py-sm-5 d-flex flex-column align-item-center justify-content-center">
            <button
              type="button"
              className="close text-blue"
              onClick={props.handleClose}
            >
              ×
            </button>
            <h2 className="modal-title text-blue mb-2" id="modalTitle">
              {props.popData.paymentOptionPopupTitle}
            </h2>
            <div
              id="modalContent"
              className="small line-height-normal"
              style={{ lineHeight: "22px" }}
              dangerouslySetInnerHTML={{
                __html: props.popData.paymentOptionPopupContent,
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }
}
export default PaymentBenefitsPopup
